<script>
import FieldError from '@utils/errors/FieldError';
import ApiError from '@utils/errors/ApiError'
import {assignFieldErrors} from "@utils/formUtil"
export default {
	data() {
		return {
			formObj : null ,
			formLoading : false ,
			labelCol : {
				'class' : 'myform-label-col'
			} ,
			wrapperCol : {
				'class' : 'myform-wrapper-col'
			} ,
			wrapperOnlyLayout: {
        wrapperCol: {
          'class' : 'myform-wrapper-offset-col'
        },
      },
		}
	} ,
	created() {
		this.formObj = this.$form.createForm(this);
	} ,
	methods: {
		displayValidateErrorMessage() {
			this.$message.error(this.$t('common.error.validate_error'),1)
		} ,
		formSubmitErrors(error) {
			if (error && (error instanceof Error)) {
				console.log("Catch Error "+JSON.stringify(error))
				if (error instanceof FieldError) {
					assignFieldErrors(this.formObj,error.fieldErrors)
					this.displayValidateErrorMessage();
					return
				} else if (error instanceof ApiError) {
					console.log("Catch Error "+JSON.stringify(error))
				}
				this.$message.error(ApiError.getDisplayErrorMessage(this,error),4)
			}
		} ,
	}

}
</script>
