<i18n src="@i18n/drone/part.th.yaml" locale="th" lang="yaml"/>
<i18n src="@i18n/drone/drone_model.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
page.title: "{name} :: จัดการอะไหล่"
page.header : "จัดการข้อมูลอะไหล่ {name}"
page.description : "จัดการข้อมูลอะไหล่สำหรับ Drone รุ่น {name} ({displayName})"

page.section.initial : "อะไหล่ตั้งต้น"
page.section.optional : "อะไหล่เสริม"

button.add_part : "เพิ่มอะไหล่"

add_model_part.success : "ทำการเพิ่มอะไหล่ {name} ให้กับ Drone รุ่นนี้เรียบร้อย"
update_model_part.success : "ทำการปรับข้อมูลอะไหล่ {name} ให้กับ Drone รุ่นนี้เรียบร้อย"

delete_model_part.confirm.message : "ต้องการยกเลิกอะไหล่นี้จาก Drone รุ่นนี้ ?"
delete_model_part.success : "ทำการยกเลิกอะไหล่ {name} ออกจาก Drone รุ่นนี้เรียบร้อย"
part.field.has_serial_no : "Serial No"

initial_parts.remark : "* ไม่สามารถเปลี่ยนแปลงข้อมูลอะไหล่ตั้งต้นได้แล้ว ต้องมีสถานะเป็น Draft เท่านั้น"
</i18n>

<template>
	<div class="page">
		<my-page-header
			:title="$t('page.header',{ name: model.name })"
			:description="$t('page.description',{ name: model.name, displayName: model.display_name })"/>
		<div class="row">
			<a-button
				class="btn-page-action left"
				type="primary"
				icon="plus"
				@click="clickAddPart">
				{{$t('button.add_part')}}
			</a-button>
			<a-button icon="left" class="right" @click="goBack">
				{{$t('common.back')}}
			</a-button>
		</div>

		<a-card v-for="section of displayList" :key="section.key" :bordered="false" style="margin-bottom:32px"
			:loading="loading">
			<div class="page-section-title">
				{{section.title}}
				<div v-if="$notEmpty(section.remark)" class="page-section-description">
					{{section.remark}}
				</div>
			</div>
			<a-table
				bordered class="mytable mytable-scroll model-part-table"
				:row-key="record => record.part_id"
				:data-source="section.value"
				:pagination="false"
				size="small">
				<a-table-column
					align="center"
					:width="30">
					<template slot-scope="text,record,index">
						{{index + 1}}.
					</template>
				</a-table-column>
				<a-table-column
					:sorter="tableSortSKU"
					:title="$t('part.field.sku')" data-index='part.sku'
					:width="150"/>
				<a-table-column
					:title="$t('common.table.column.detail')" data-index="part.name"
					:sorter="tableSortName">
					<template slot-scope="text,record">
						<MyPopover overlay-class-name="mycard-popover"
							:destroy-tooltip-on-hide="true">
							<template slot="content">
								<PartLiteCard :part="record.part" size="small"  link-target="_blank"/>
							</template>
							{{record.part.name}}
						</MyPopover>
					</template>
				</a-table-column>
				<a-table-column
					:title="$t('part.field.part_type')" data-index='part.part_type'
					:width="120"
					:sorter="tableSortPartType">
					<template slot-scope="text">
						{{$tenum('part_type',text)}}
					</template>
				</a-table-column>
				<a-table-column
					:title="$t('part.field.has_serial_no')" data-index='part.has_serial_no'
					align="center"
					:width="75">
					<template slot-scope="hasSerialNo">
						<a-icon v-if="hasSerialNo" type="check" class="text-success"/>
					</template>
				</a-table-column>
				<a-table-column
					:title="$t('model_part.field.total')"
					data-index="total"
					:sorter="tableSortTotal"
					:width="120"
					align="right">
					<template slot-scope="total">
						{{$tc_my('part.number.display',total)}}
					</template>
				</a-table-column>

				<a-table-column
					:title="$t('model_part.field.remark')"
					data-index="remark"
					:width="50"
					align="center">
					<template slot-scope="remark">
						<MyTooltip v-if="$notEmpty(remark)"
							:destroy-tooltip-on-hide="true">
							<div slot="title" v-trim class="multiline">
								{{remark}}
							</div>
							<a-icon type="message" class="cursor-pointer" />
						</MyTooltip>
					</template>
				</a-table-column>

				<a-table-column
					v-if="section.showAction"
					class="mytable-action-col"
					:width="75"
					align="center"
					:title="$t('common.table.column.action')">
					<template slot-scope="record">
						<a @click="() => clickEditPart(record)">
							<a-icon type="edit" />
						</a>
						<a-divider type="vertical"/>
						<MyPopconfirm :title="$t('delete_model_part.confirm.message')"
							placement="left"
							:destroy-tooltip-on-hide="true"
							@confirm="() => handleModelPartRemove(record)">
            	<a class="text-error"><a-icon type="delete" /></a>
          	</MyPopconfirm>
					</template>
				</a-table-column>
			</a-table>
		</a-card>

		<DroneModelPartModal
			ref="modal"
			:model="model"
			:loading="modelPartModalLoading"
			:current-part-id-list="currentPartIdList"
			:can-manage-initial="canManageInitialPart"
			@submit="handleModelPartModalSubmit"/>
	</div>
</template>

<script>
import axios from "axios"
import Vue from "vue"
import PageMixin from '@mixins/PageMixin.vue'
import SortModelPartMixin from '@mixins/drone/SortModelPartMixin.vue'
import {Table,Divider} from "ant-design-vue"
import DroneModelPartModal from "@components/drone/DroneModelPartModal.vue"
import MyTooltip from "@components/common/MyTooltip.vue"
import MyPopover from "@components/common/MyPopover.vue"
import MyPopconfirm from "@components/common/MyPopconfirm.vue"
import PartLiteCard from "@components/part/PartLiteCard.vue"
import { mapGetters  } from 'vuex'
import ApiError from '@utils/errors/ApiError'


export default {
	components : {
		DroneModelPartModal , PartLiteCard , MyTooltip , MyPopover, MyPopconfirm ,
		"a-divider" : Divider,
		"a-table" : Table, "a-table-column" : Table.Column
	} ,
	mixins : [PageMixin,SortModelPartMixin] ,
	props : {
		model : {
			type: null,
			required: true,
			default : () => []
		}
	},
	data(){
		return {
			loading : false,
			modelParts : [],
			modelPartModalLoading : false,
		}
	} ,
	page() {
		return {
			title : this.$t('page.title',{name:this.model.name})
		}
	} ,
	computed : {
		...mapGetters('drone',['getPartById']) ,
		displayList(){
			const remark = this.canManageInitialPart ? undefined :  this.$t('initial_parts.remark')

			return [
				{
					key : 'initial' ,
					title : this.$t('page.section.initial') ,
					showAction : this.canManageInitialPart ,
					value : this.initialModelParts ,
					remark
				} ,
				{
					key : 'optional' ,
					title : this.$t('page.section.optional') ,
					showAction : true,
					value : this.optionalModelParts
				} ,
			]
		} ,

		currentPartIdList() {
			return this.modelParts.map((part)=> part.part_id)
		} ,
		initialModelParts() {
			return this.modelParts.filter((part) => part.type == 'initial')
		} ,
		optionalModelParts() {
			return this.modelParts.filter((part) => part.type == 'optional')
		} ,
		canManageInitialPart() {
			return this.model.id && this.model.status == 'draft'
		}
	} ,
	watch: {
		model : {
			handler() {
				this.fetchData()
			} ,
			deep: true
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {

		clickAddPart() {
			this.$refs.modal.openPartModal({})
		} ,
		clickEditPart(modelPart) {
			this.$refs.modal.openPartModal(modelPart)
		} ,
		handleModelPartRemove(modelPart) {
			this.loading = true
			axios.delete("/api/drone-models/"+this.model.id+"/parts/"+modelPart.part_id).then((response) => {
				const modelParts = response.data.data.model_parts
				this.modelParts = modelParts
				for(const modelPart of this.modelParts) {
					Vue.set(modelPart,"part",this.getPartById(modelPart.part_id))
				}
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=> {
				this.loading = false
			})
		} ,
		handleModelPartModalSubmit(payload) {
			const formData = payload.formData
			const mode = payload.mode
			const partId = payload.partId

			this.modelPartModalLoading = true

			const axiosConfig = {
				data : formData
			}
			let successMessageKey = ""
			if (mode == 'update') {
				axiosConfig.url = "/api/drone-models/"+this.model.id+"/parts/"+partId
				axiosConfig.method = "put"
				successMessageKey = "update_model_part.success"
			} else {
				axiosConfig.url = "/api/drone-models/"+this.model.id+"/parts/add"
				axiosConfig.method = "post"
				successMessageKey = "add_model_part.success"
			}
			axios(axiosConfig).then((response) => {
				const modelPart = response.data.data.model_part;
				modelPart.part = this.getPartById(modelPart.part_id)
				// replace this value
				const index = this.modelParts.findIndex((part) => part.part_id === modelPart.part_id);
				if (index >= 0) {
					this.modelParts.splice(index,1,modelPart)
				} else {
					this.modelParts.push(modelPart)
				}
				this.$refs.modal.closePartModal()
				this.$message.success(this.$t(successMessageKey,{name : modelPart.part.name}))
			}).catch((error)=> {
				this.$refs.modal.formErrors(error)
			}).finally(() => {
				this.modelPartModalLoading = false
			})
		} ,

		fetchData() {
			if (!this.model || !this.model.id)
				return

			this.showPageLoading(true)
			this.loading = true
			axios.get("/api/drone-models/"+this.model.id+"/parts").then((response) => {
				this.modelParts = response.data.data.model_parts;
				for(const modelPart of this.modelParts) {
					Vue.set(modelPart,"part",this.getPartById(modelPart.part_id))
				}
			}).catch((error) => {
				this.fetchError(error);
			}).finally(()=>{
				this.hidePageLoading()
				this.loading = false
			})
		} ,
		goBack() {
			this.$open({name:"drone_model/parts",params:{id:this.model.id}})
		}
	}
}
</script>
